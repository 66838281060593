<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="6">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            fab
                            color="blue"
                            :loading="isloading"
                            :disabled="isloading"
                            class="mx-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="createFolder"
                        >
                            <v-icon class="white--text">mdi-folder-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>Crear carpeta</span>
                </v-tooltip>
            </v-col>
            <v-col cols="12" md="6" class="d-flex">
                <v-file-input
                    multiple
                    outlined
                    dense
                    label="Archivos"
                    @change="addFile"
                ></v-file-input>
            </v-col>

            <v-col cols="12">
                <div class="d-flex flex-wrap gap-3">
                    <template v-for="file in item.archivos_organizados">
                        <!-- Archivos -->
                        <v-card
                            class="me-3 mb-3"
                            v-if="file.type == 'file' || file.type == null"
                        >
                            <v-card-text
                                style="height: 100%; align-items: center"
                                class="d-flex justify-space-between"
                            >
                                <a
                                    :href="
                                        file.id
                                            ? `storage/${tipo}/${file.url}`
                                            : '#'
                                    "
                                    :target="file.id ? '_blank' : '_self'"
                                    >{{ file.file_name }}</a
                                >
                                <v-icon
                                    class="pointer"
                                    @click="deleteFile(file)"
                                    >mdi-trash-can-outline</v-icon
                                >
                            </v-card-text>
                        </v-card>

                        <!-- Carpetas -->
                        <v-card
                            v-if="file.type == 'folder'"
                            class="me-3 mb-3 pointer"
                            @click="openFilesFolderDialog(file)"
                        >
                            <v-card-text>
                                <div class="d-flex justify-end">
                                    <!-- .stop para evitar conflictos con el card click -->
                                    <v-icon
                                        class="pointer"
                                        @click.stop="
                                            openConfirmDialog(file.name)
                                        "
                                        >mdi-trash-can-outline</v-icon
                                    >
                                </div>

                                <!-- Detiene la propagación del evento click -->
                                <v-text-field
                                    prepend-icon="mdi-folder-outline"
                                    v-model="file.name"
                                    dense
                                    @click.stop
                                ></v-text-field>
                            </v-card-text>
                        </v-card>
                    </template>
                </div>
            </v-col>
        </v-row>

        <!-- Archivos por carpeta -->
        <v-dialog v-model="isFilesFolderDialogVisible" max-width="800px">
            <v-card>
                <v-card-title class="text-h5 dialog-title">
                    {{ folder.name }}
                </v-card-title>
                <v-card-text class="mt-3">
                    <v-row>
                        <v-col cols="12">
                            <v-file-input
                                multiple
                                outlined
                                dense
                                label="Archivos"
                                v-model="files"
                                @change="addFileToFolder(folder, files)"
                            ></v-file-input>
                        </v-col>
                        <v-col cols="12">
                            <v-data-table
                                :headers="headers"
                                hide-default-header
                                hide-default-footer
                                :items="folder.files"
                            >
                                <template v-slot:item.file_name="{ item }">
                                    <a
                                        :href="
                                            item.id
                                                ? `storage/${tipo}/${item.url}`
                                                : '#'
                                        "
                                        :target="item.id ? '_blank' : '_self'"
                                        >{{ item.file_name }}</a
                                    >
                                </template>
                                <template v-slot:item.actions="{ item }">
                                    <v-icon
                                        @click="
                                            deleteFileInFolder(folder, item)
                                        "
                                        small
                                        class="mr-2"
                                        color="red"
                                        style="font-size: 25px"
                                        title="BORRAR"
                                        >mdi-trash-can</v-icon
                                    >
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="d-flex justify-center">
                    <v-btn
                        color="success"
                        @click="isFilesFolderDialogVisible = false"
                        >Confirmar</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <ConfirmDialog
            ref="confirmDialog"
            v-on:confirm="deleteFolder(folder_name)"
            titulo="Eliminar carpeta"
            texto="¿Está seguro que desea eliminar esta carpeta? Eliminará también los archivos que se encuentren en ella"
        />
    </v-container>
</template>
<script>
import ConfirmDialog from "./VConfirmDialog.vue";
export default {
    props: ["value", "tipo"],
    components: { ConfirmDialog },
    data() {
        return {
            item: {},
            folder: {
                name: "",
                files: [],
            },
            headers: [
                { text: "Nombre", value: "file_name" },
                { text: "Acciones", value: "actions" },
            ],
            files: [],
            isFilesFolderDialogVisible: false,
            folder_name: "",
        };
    },
    created() {
        if (this.value != null) {
            this.item = this.value;
        }
    },
    methods: {
        // Acciones de carpetas
        createFolder() {
            if (!Array.isArray(this.item.archivos_organizados)) {
                this.item.archivos_organizados = [];
            }

            const folder = {
                type: "folder",
                name: "",
                files: [],
            };

            this.item.archivos_organizados.push(folder);
        },
        openConfirmDialog(name) {
            this.item.archivos_organizados.forEach((element) => {
                if (element.type === "folder") {
                    if (element.name == name && element.files.length) {
                        this.folder_name = name;
                        this.$emit("open");
                        return;
                    } else {
                        this.deleteFolder(name);
                        return;
                    }
                }
            });
        },
        deleteFolder(name) {
            this.deleteFolderFromDatabase(name);
            const index = this.item.archivos_organizados.findIndex(
                (element) => element.type === "folder" && element.name == name
            );
            if (index !== -1) {
                this.item.archivos_organizados.splice(index, 1);
            }
        },
        openFilesFolderDialog(folder) {
            this.folder = folder;
            this.isFilesFolderDialogVisible = true;
        },
        addFileToFolder(folder, fileList) {
            if (!fileList.length) return;
            for (const file of fileList) {
                folder.files.push({
                    id: null,
                    id_item: null,
                    file_name: file.name,
                    url: null,
                    file: file,
                    created_at: moment().format("YYYY-M-DD H:m:ss"),
                });
            }
            this.files = [];
        },
        deleteFileInFolder(folder, file) {
            this.deleteFileFromDatabase(file);
            const index = folder.files.findIndex((element) => element == file);
            folder.files.splice(index, 1);
        },

        // Acciones de archivos sueltos
        addFile(fileList) {
            if (!fileList.length) return;
            for (const file of fileList) {
                this.item.archivos_organizados.push({
                    id: null,
                    type: "file",
                    id_item: null,
                    file_name: file.name,
                    url: null,
                    file: file,
                    created_at: moment().format("YYYY-M-DD H:m:ss"),
                });
            }
        },
        deleteFile(file) {
            this.deleteFileFromDatabase(file);
            const index = this.item.archivos_organizados.findIndex(
                (element) =>
                    (element.type === "file" || element.type == null) &&
                    element == file
            );

            if (index !== -1) {
                this.item.archivos_organizados.splice(index, 1);
            }
        },
        deleteFileFromDatabase(item) {
            axios.get(`api/delete-file/${this.tipo}/${item.id}`).then(
                (res) => {
                    this.$toast.sucs("Archivo eliminado");
                    this.deleteFromList(item);
                },
                (res) => {
                    this.$toast.error("Error eliminando archivo");
                }
            );
        },
        deleteFolderFromDatabase(folder_name) {
            axios
                .get(`api/delete-folder`, {
                    params: {
                        folder_name: folder_name,
                    },
                })
                .then(
                    (res) => {
                        this.$toast.sucs("Carpeta eliminado");
                        // this.deleteFromList(item);
                    },
                    (res) => {
                        this.$toast.error("Error eliminando carpeta");
                    }
                );
        },
    },
    watch: {
        value: function (val) {
            this.item = val;
            // console.log("item", this.item);
        },
        item: function (val) {
            // console.log("aqui", val);
            this.$emit("input", val);
        },
    },
    computed: {
        isloading() {
            return this.$store.getters.getloading;
        },
    },
};
</script>
