<template>
    <v-card class="pa-3 ma-3">
        <v-toolbar flat color="#1d2735" dark>
            <v-icon class="white--text" style="font-size: 45px">
                mdi-file-table-outline
            </v-icon>
            <pre><v-toolbar-title><h2>Lista de presupuestos</h2></v-toolbar-title></pre>
        </v-toolbar>
        <loader v-if="isloading"></loader>
        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    fab
                    :to="'/lista-presupuestos'"
                    :loading="isloading"
                    :disabled="isloading"
                    color="blue"
                    class="mx-3 mt-2"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon class="white--text"
                        >mdi-arrow-left-bold-outline</v-icon
                    >
                </v-btn>
            </template>
            <span>Volver</span>
        </v-tooltip>
        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    fab
                    :to="{
                        path: '/registrar-presupuesto',
                    }"
                    :loading="isloading"
                    :disabled="isloading"
                    color="orange darken-1"
                    class="mt-2"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon class="white--text">mdi-plus-box</v-icon>
                </v-btn>
            </template>
            <span>Nuevo presupuesto</span>
        </v-tooltip>
        <!-- <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    fab
                    @click="Exportar"
                    :loading="isloading"
                    :disabled="isloading"
                    color="green darken-1"
                    class="ml-3 mt-2"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon class="white--text">mdi-file-excel</v-icon>
                </v-btn>
            </template>
            <span>Exportar</span>
        </v-tooltip> -->

        <v-card-text>
            <v-row>
                <v-col cols="12" md="6" justify="center">
                    <FilterComponent
                        :headers="filter_headers"
                        v-model="filtros"
                    ></FilterComponent>
                </v-col>
                <!-- <v-col cols="12" md="4">
                    <v-card>
                        <v-card-text>
                            <h3>Total: {{ importe_total | format_precio }}</h3>
                        </v-card-text>
                    </v-card>
                </v-col> -->
                <v-col></v-col>

                <v-col cols="12">
                    <v-data-table
                        dense
                        :server-items-length="totalPresupuestos"
                        :headers="headers"
                        :items="presupuestos"
                        :items-per-page="15"
                        item-key="id"
                        class="elevation-1"
                        :sort-by="['nro_anio_factura']"
                        :sort-desc="[true]"
                        :options.sync="options"
                        @update:options="onOptionsUpdate"
                    >
                        <template v-slot:item.fecha="{ item }">
                            {{ item.fecha | format_date }}
                        </template>
                        <template v-slot:item.url="{ item }">
                            <a :href="item.url" target="_blank">
                                <v-icon> mdi-file-pdf-box </v-icon>
                            </a>
                        </template>
                        <template
                            v-slot:item.cliente_real.nombre_fiscal="{ item }"
                        >
                            <template v-if="item.cliente_real">
                                <template
                                    v-if="item.cliente_real.nombre_fiscal"
                                    >{{
                                        item.cliente_real.nombre_fiscal
                                    }}</template
                                >
                                <template v-else>{{
                                    item.cliente_real.nombre
                                }}</template>
                            </template>
                        </template>
                        <template v-slot:item.total="{ item }">
                            {{ item.total | format_precio }}
                        </template>

                        <template v-slot:item.pagada="{ item }">
                            <div
                                :style="`background-color:${
                                    item.esta_pagada == 1
                                        ? 'green'
                                        : item.esta_pagada == 0
                                        ? 'red'
                                        : item.esta_pagada == 2
                                        ? 'orange'
                                        : 'blue'
                                }`"
                                class="circle"
                            ></div>
                        </template>
                        <template v-slot:item.enviado="{ item }">
                            <div
                                :style="`background-color:${
                                    item.enviado == 1 ? 'green' : 'red'
                                }`"
                                class="circle"
                            ></div>
                        </template>
                        <template v-slot:item.actions="{ item, index }">
                            <!-- <v-icon
                                @click="
                                    mail_dialog = true;
                                    id_factura = item.id;
                                    kit_seleccionado = item.kit;
                                "
                                small
                                class="mr-2"
                                color="red"
                                style="font-size: 25px"
                                title="Enviar Email"
                                >mdi-email</v-icon
                            > -->
                            <router-link
                                :to="{
                                    path: `/registrar-presupuesto?id=${item.id}`,
                                }"
                                class="action-buttons"
                            >
                                <v-icon
                                    small
                                    class="mr-2"
                                    color="#1d2735"
                                    style="font-size: 25px"
                                    title="EDITAR"
                                    >mdi-pencil-outline</v-icon
                                >
                            </router-link>

                            <!-- Mostrar el botón de borrar solo si es la última fila y si no ha sido enviada al cliente aun -->
                            <v-icon
                                @click="openDeleteDialog(item.id)"
                                small
                                class="mr-2"
                                color="red"
                                style="font-size: 25px"
                                title="BORRAR"
                                >mdi-trash-can</v-icon
                            >
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>

        <!-- Eliminar presupuesto -->
        <DeleteDialog
            v-model="isDeleteDialogVisible"
            :id="selectedPresupuestoId"
            @delete="deletePresupuesto"
        />
    </v-card>
</template>
<script>
import DeleteDialog from "../../../components/general/DeleteDialog.vue";
import FilterComponent from "../../../components/general/FilterComponent.vue";
import { debounce } from "lodash";
export default {
    components: { DeleteDialog, FilterComponent },
    data() {
        return {
            headers: [
                {
                    text: "Nro. Presupuesto",
                    value: "nro_presupuesto",
                    sortable: false,
                },
                { text: "Fecha", value: "fecha", sortable: false },
                { text: "Cliente", value: "nombre_cliente", sortable: false },
                { text: "Total", value: "total", sortable: false },
                { text: "PDF", value: "url", sortable: false },
                // { text: "Enviado", value: "enviado", sortable: false },
                { text: "Acciones", value: "actions", sortable: false },
            ],
            presupuestos: [],
            options: {
                page: 1,
                itemsPerPage: 15,
                sortBy: [],
                sortDesc: [],
            },
            totalPresupuestos: 0,
            filter_headers: [
                {
                    title: "Cliente",
                    type: "cliente",
                    active: false,
                    model: "cliente",
                    item_text: "nombre",
                    item_value: "id",
                    items: [],
                },
                {
                    title: "Fecha de creación",
                    type: "date",
                    active: true,
                    model: "fecha",
                },
            ],
            filtros: {
                search: "",
            },
            isDeleteDialogVisible: false,
            selectedPresupuestoId: null,
        };
    },
    created() {
        this.getPresupuestos();
    },
    methods: {
        getPresupuestos() {
            let params = {
                search: this.filtros.search,
            };

            if (
                this.filtros.fecha?.start != "" &&
                this.filtros.fecha?.start != null
            ) {
                params.start = this.filtros.fecha.start;
            }

            if (
                this.filtros.fecha?.end != "" &&
                this.filtros.fecha?.end != null
            ) {
                params.end = this.filtros.fecha.end;
            }

            axios
                .get("api/presupuestos", {
                    params: params,
                })
                .then((res) => {
                    const response = res.data.success;
                    this.presupuestos = response.data;
                    this.totalPresupuestos = response.total;
                })
                .catch((err) => {
                    console.log(err.responde?.data);
                });
        },
        onOptionsUpdate(newOptions) {
            this.options = newOptions;

            if (this.options.sortBy.length > 0) {
                this.sortBy = this.options.sortBy[0];
            } else {
                this.options.sortBy = [this.sortBy];
            }
            if (this.options.sortDesc.length > 0) {
                this.sortDesc = this.options.sortDesc[0];
            } else {
                this.options.sortDesc = [!this.sortDesc];
            }

            this.getPresupuestos();
        },
        openDeleteDialog(id) {
            this.selectedPresupuestoId = id;
            this.isDeleteDialogVisible = true;
        },
        deletePresupuesto(id) {
            axios
                .delete(`api/presupuesto/${id}`)
                .then((res) => {
                    this.$toast.sucs(res.data.success);
                    this.isDeleteDialogVisible = false;
                    this.getPresupuestos();
                })
                .catch((err) => {
                    console.log(err.responde?.data);
                });
        },
    },
    watch: {
        filtros: {
            deep: true,
            handler: debounce(function (val) {
                this.getPresupuestos();
            }, 500),
        },
    },
    computed: {
        isloading: function () {
            return this.$store.getters.getloading;
        },
    },
};
</script>
