export const rules = { 
    required: (value) => {
        return !!value || 'Requerido.'
    },
    file: (value) => {
        return (
            !value ||
            !value.length ||
            value[0].size < 52428800 ||
            `El archivo no puede pesar mas de 50MB!`
        );
    },
    cifOrDni: value => {
        const regex = /^([XYZ]\d{7}[A-Z])|(\d{8}[A-Z])|([ABCDEFGHJKLMNPQRSUVW]\d{7}[0-9A-J])$/;
        return regex.test(value) || 'Debe ser un CIF o DNI válido.';
    },
    number: (value) => {
        return /^\d+$/.test(value) || "Campo numérico"
    },
    email: (value) => {
        const pattern =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return pattern.test(value) || "E-mail Inválido.";
    },
} 