<template>
    <v-dialog 
        v-model="dialog" 
        :max-width="isMobile ? '80vw' : '500px'"
    >
        <v-card>
            <v-card-title class="text-h5 dialog-title"> Aviso </v-card-title>
            <v-card-text>
                <h2 class="text-center mt-3">
                    ¿Estás seguro que deseas eliminar?
                </h2>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
                <v-btn @click="dialog = false" color="error">Cancelar</v-btn>
                <v-btn @click="confirm()" color="success">Confirmar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props: ["value", "id"],
    data() {
        return {
            dialog: false,
        };
    },
    created() {
        if (this.value != null) {
            this.dialog = this.value;
        }
    },
    methods: {
        confirm() {
            this.$emit("delete", this.id);
        },
    },
    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.smAndDown; // Detecta pantallas más pequeñas
        },
    },
    watch: {
        value: function (val) {
            this.dialog = val;
        },
        dialog: function (val) {
            this.$emit("input", val);
        },
    },
};
</script>
<style scoped>
.v-dialog__content {
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    align-items: center;    /* Centra verticalmente */
}

@media (max-width: 768px) {
    .v-dialog__content {
        left: 0 !important; /* Asegura que no haya desalineación horizontal */
        width: 100vw;       /* Haz que ocupe todo el ancho de la pantalla */
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>