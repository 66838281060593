<template>
    <v-container>
        <loader v-if="isloading"></loader>
        <v-card>
            <v-toolbar flat color="#1d2735" dark>
                <v-toolbar-title>
                    {{ $route.query.id != null ? "Editar" : "Crear" }} Proyecto
                </v-toolbar-title>
            </v-toolbar>
            <v-tabs horizontal>
                <!-- Pestañas -->
                <v-tab>
                    <v-icon left>mdi-book-variant-multiple</v-icon>Proyecto
                </v-tab>
                <v-tab v-if="admin">
                    <v-icon left>mdi-account</v-icon>Cliente
                </v-tab>

                <v-tab v-if="admin">
                    <v-icon left>mdi-folder-multiple-outline</v-icon>Archivo
                </v-tab>
                <v-tab v-if="admin || marketing">
                    <v-icon left>mdi-account-group</v-icon>Usuarios Asignados
                </v-tab>
                <v-tab v-if="admin && proyecto.id != null">
                    <v-icon left>mdi-calendar</v-icon>Seguimiento
                </v-tab>
                <v-tab v-if="admin">
                    <v-icon left>mdi-cash</v-icon>Resumen Pagos
                </v-tab>
                <v-tab v-if="proyecto.id != null">
                    <v-icon left>mdi-ticket-account</v-icon>Tickets
                </v-tab>

                <!-- Pestañas -->
                <!-- Form Proyecto -->
                <v-tab-item class="pa-3 ma-1">
                    <v-card flat>
                        <v-row dense>
                            <v-col cols="12" md="4" class="pt-3 pb-0">
                                <date-select
                                    outlined
                                    dense
                                    v-model="proyecto.fecha_alta"
                                    label="Fecha de Alta Servicio"
                                    append-icon="mdi-calendar"
                                >
                                </date-select>
                            </v-col>
                            <v-col cols="12" md="8" class="mt-2">
                                <v-text-field
                                    dense
                                    outlined
                                    :error-messages="
                                        errors.errors['nombre']
                                            ? errors.errors['nombre'][0]
                                            : null
                                    "
                                    v-model="proyecto.nombre"
                                    label="Nombre"
                                >
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" md="6">
                                <v-text-field
                                    dense
                                    outlined
                                    :error-messages="
                                        errors.errors['pvp']
                                            ? errors.errors['pvp'][0]
                                            : null
                                    "
                                    v-model="proyecto.pvp"
                                    label="Precio Proyecto"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <dynamic_select
                                    :dense="true"
                                    outlined
                                    title="Producto Contratado"
                                    v-model="proyecto.servicio_id"
                                    v-bind:estados="servicios"
                                    show="nombre"
                                    @create="createServicio"
                                    @delete="deleteServicio"
                                    @getEstado="updateServicioS"
                                    @update="updateServicio"
                                >
                                    <v-row>
                                        <v-col>
                                            <v-text-field
                                                v-model="servicio.nombre"
                                                type="text"
                                                label="Nombre"
                                                class="mt-3"
                                                required
                                                outlined
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </dynamic_select>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="12" md="12">
                                <!-- Descripcion -->
                                <small
                                    ><strong
                                        >Detalles del Servicio
                                        contratado</strong
                                    ></small
                                >
                                <!-- Editor -->
                                <ckeditor
                                    style="cursor: none"
                                    :editor="editor"
                                    v-model="proyecto.detalle_servicio"
                                    :config="editorConfig"
                                ></ckeditor>
                            </v-col>
                        </v-row>
                        <v-row
                            dense
                            justify="space-between"
                            align="center"
                            v-if="admin"
                        >
                            <!-- <v-col cols="12" md="4" lg="2">
                                <v-checkbox class="mt-7" v-model="proyecto.facturado" label="Facturado"></v-checkbox>
                            </v-col> -->
                            <!--v-col cols="12" md="3">
                                <v-text-field
                                    class="mt-7"
                                    dense
                                    outlined
                                    v-model="proyecto.pvp_gasto"
                                    label="Gasto Externo"
                                ></v-text-field>
                            </v-col 
                            <v-col cols="12" md="3">
                                <v-text-field
                                    class="mt-7"
                                    dense
                                    outlined
                                    v-model="proyecto.minutos_estimados"
                                    label="Minutos Estimados"
                                ></v-text-field>
                            </v-col>-->

                            <v-col
                                cols="12"
                                md="4"
                                lg="2"
                                v-if="proyecto.id != null"
                            >
                                <div
                                    class="pointer mt-7"
                                    style="display: flex; align-items: center"
                                    @click="dialog_semaforo = true"
                                >
                                    <div
                                        class="ball"
                                        :style="`background-color:${getColorFacturacion.color}`"
                                    ></div>
                                    <div>{{ getColorFacturacion.text }}</div>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <GastoExterno
                                    v-model="proyecto.gastos_externos"
                                ></GastoExterno>
                            </v-col>
                        </v-row>
                        <v-row dense v-if="admin">
                            <v-col cols="12" md="12" class="mt-4">
                                <!-- Observaiones -->
                                <small><strong>Observaciones</strong></small>
                                <!-- Editor -->
                                <ckeditor
                                    style="cursor: none"
                                    :editor="editor"
                                    v-model="proyecto.observaciones"
                                    :config="editorConfig"
                                ></ckeditor>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-tab-item>
                <!-- Form Proyecto -->

                <!-- Form Cliente -->
                <v-tab-item class="pa-3 ma-1" v-if="admin">
                    <form-usuario
                        :usuarios="usuarios"
                        :provincias="provincias"
                        :editorConfig="editorConfig"
                        :editor="editor"
                        :editorData="editorData"
                        :errors="errors"
                        :proyecto="proyecto"
                        :empleados="empleados"
                    >
                    </form-usuario>
                </v-tab-item>
                <!-- Form Cliente -->

                <!-- Form Archivo -->
                <v-tab-item class="pa-3 ma-1" v-if="admin">
                    <!-- <tab-archivo
                        tipo="potencial"
                        :item="proyecto"
                    ></tab-archivo> -->
                    <FileManager v-model="proyecto" tipo="potencial" />
                </v-tab-item>
                <!-- Form Archivo -->

                <!-- Form Usuarios Asignados -->
                <v-tab-item class="pa-3 ma-1" v-if="admin || marketing">
                    <v-card flat>
                        <div class="font-weight-bold mb-3 black--text">
                            AGREGAR USUARIO A PROYECTO
                        </div>
                        <v-row dense>
                            <v-col cols="12" md="4">
                                <v-autocomplete
                                    dense
                                    outlined
                                    prepend-icon="mdi-account-search-outline"
                                    v-model="user_to_pick"
                                    :error-messages="
                                        errors.errors['empleado.nombre']
                                            ? errors.errors[
                                                  'enmpleado.nombre'
                                              ][0]
                                            : null
                                    "
                                    return-object
                                    :items="empleados"
                                    item-value="id"
                                    item-text="nombre"
                                    label="Seleccione o Cree empleado Nuevo"
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <div class="mb-5">
                            <v-btn
                                v-if="user_to_pick"
                                @click="addUserList()"
                                color="primary"
                                class="white--text"
                                rounded
                                >Agregar Usuario</v-btn
                            >
                        </div>
                        <v-data-table
                            :headers="headers_usuarios"
                            :items="itemsUsuarios"
                            :items-per-page="5"
                            class="elevation-1"
                        >
                            <template v-slot:item.fecha="{ item }">{{
                                item.fecha.substr(0, 10)
                            }}</template>
                            <template v-slot:item.role="{ item }">
                                <template v-if="item.usuario != null">
                                    {{
                                        item.usuario.role == 1
                                            ? "Administrador"
                                            : item.usuario.role == 2
                                            ? "Cliente"
                                            : item.usuario.role == 3
                                            ? "Empleado"
                                            : "Potencial"
                                    }}
                                </template>
                                <template v-else> Eliminado </template>
                            </template>
                            <template v-slot:item.action="{ item }">
                                <v-icon
                                    @click="deleteItemUsers(item)"
                                    color="red"
                                    >mdi-delete</v-icon
                                >
                            </template>
                        </v-data-table>
                    </v-card>
                </v-tab-item>
                <!-- Form Usuarios Asignados -->

                <!-- Form Seguimiento -->
                <v-tab-item
                    class="pa-3 ma-1"
                    v-if="vendedor && proyecto.id != null"
                >
                    <v-card flat>
                        <div
                            style="
                                display: flex;
                                justify-content: space-between;
                            "
                        >
                            <div class="font-weight-bold mb-3 black--text">
                                AGREGAR TAREA A SEGUIMIENTO DEL PROYECTO
                            </div>
                            <v-btn
                                fab
                                color="success"
                                @click="dialog_whatsapp = true"
                            >
                                <v-icon color="white">mdi-whatsapp</v-icon>
                            </v-btn>
                        </div>
                        <v-row>
                            <v-col cols="12">
                                <v-form
                                    ref="formSeguimiento"
                                    v-model="validFormSeguimiento"
                                    class="mt-3"
                                >
                                    <v-row>
                                        <v-col cols="12">
                                            <date-select
                                                v-model="seguimiento.fecha"
                                                label="Fecha"
                                                :rules="[rules.required]"
                                            >
                                            </date-select>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-checkbox
                                                label="Alarma"
                                                v-model="seguimiento.alarma"
                                            ></v-checkbox>
                                        </v-col>
                                        <v-col cols="6" v-if="role == 1">
                                            <v-checkbox
                                                label="Activo"
                                                v-model="seguimiento.activo"
                                            ></v-checkbox>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-autocomplete
                                                outlined
                                                label="Horas"
                                                v-model="seguimiento.hora"
                                                :items="horas"
                                                item-value="value"
                                                item-text="text"
                                                :rules="[rules.required]"
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-textarea
                                                outlined
                                                label="Comentario"
                                                v-model="seguimiento.comentario"
                                                :rules="[rules.required]"
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="12">
                                            <v-btn
                                                v-if="!seguimiento.id"
                                                :disabled="isloading"
                                                color="success"
                                                class="white--text"
                                                @click="saveSeguimiento"
                                                >Añadir</v-btn
                                            >
                                            <v-btn
                                                @click="saveSeguimiento"
                                                v-if="seguimiento.id"
                                                :disabled="isloading"
                                                color="success"
                                                class="white--text"
                                                >Actualizar</v-btn
                                            >
                                            <v-btn
                                                @click="deleteSeguimiento"
                                                v-if="seguimiento.id"
                                                :disabled="isloading"
                                                color="red"
                                                class="white--text"
                                                >Eliminar</v-btn
                                            >
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-col>
                        </v-row>
                        <v-data-table
                            dense
                            :headers="seguimientos_headers"
                            :items="Seguimientos"
                            :items-per-page="15"
                            item-key="id"
                            class="elevation-1"
                            :sort-by="['nombre']"
                            :sort-desc="[false]"
                        >
                            <template v-slot:item.fecha="{ item }">
                                <span>{{ item.fecha | format_date }}</span>
                            </template>
                            <template v-slot:item.action="{ item }">
                                <v-icon
                                    @click="seguimiento = item"
                                    small
                                    class="mr-2"
                                    color="#1d2735"
                                    style="font-size: 25px"
                                    title="EDITAR"
                                    >mdi-pencil-outline</v-icon
                                >
                                <v-icon
                                    @click="deleteSeguimiento(item)"
                                    small
                                    class="mr-2"
                                    color="red"
                                    style="font-size: 25px"
                                    title="BORRAR"
                                    >mdi-trash-can</v-icon
                                >
                            </template>
                        </v-data-table>
                    </v-card>
                </v-tab-item>
                <!-- Form Seguimiento -->

                <!-- Resumen Pagos del Proyecto -->
                <v-tab-item class="pa-3 ma-1" v-if="admin">
                    <v-card flat>
                        <div class="font-weight-bold mb-4 black--text">
                            FACTURAS DEL PROYECTO
                        </div>
                        <v-data-table
                            :headers="headers_proyectos"
                            :items="facturasProyectos"
                            :items-per-page="5"
                            class="elevation-4"
                        >
                            <template v-slot:item.fecha="{ item }">{{
                                item.fecha | format_date
                            }}</template>
                            <template v-slot:item.total="{ item }"
                                >{{ item.total }} €</template
                            >
                            <template
                                v-slot:item.total_pagos_ingresos="{ item }"
                                >{{ item.total_pagos_ingresos }} €</template
                            >
                        </v-data-table>
                    </v-card>
                    <div class="font-weight-bold my-4 black--text">
                        RESUMEN CONTABLE DEL PROYECTO
                    </div>
                    <v-card class="px-2 py-2 my-4 elevation-4">
                        <span class="font-weight-bold"
                            >Total Proyecto: {{ proyecto.pvp }} €</span
                        ><br />
                        <span class="font-weight-bold"
                            >Total Pagado:
                            {{ this.total_pagos_ingresos }} €</span
                        ><br />
                        <span class="font-weight-bold"
                            >Total Pendiente: {{ this.total_pendiente }} €</span
                        ><br />
                        <span class="font-weight-bold"
                            >Total Gastos: {{ this.total_gastos }} €</span
                        >
                    </v-card>
                </v-tab-item>
                <!-- Resumen Pagos del Proyecto -->

                <!-- Listado de tickets asociados al proyecto -->
                <v-tab-item class="pa-3 ma-1">
                    <v-card flat>
                        <div class="font-weight-bold mb-4 black--text">
                            TICKETS DEL PROYECTO
                        </div>
                        <TicketDataTableVue
                            :id_proyecto="proyecto.id"
                            :actions="false"
                        />
                    </v-card>
                </v-tab-item>
                <!-- Listado de tickets asociados al proyecto -->
            </v-tabs>
        </v-card>

        <!-- Botones Proyecto 1a linea -->
        <v-row class="mt-3" v-if="admin">
            <v-col cols="12">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            fab
                            @click="goingTo"
                            :loading="isloading"
                            :disabled="isloading"
                            color="blue-grey lighten-2"
                            class="mx-2"
                            v-bind="attrs"
                            v-on="on"
                            readonly
                        >
                            <v-icon class="white--text"
                                >mdi-text-box-search-outline</v-icon
                            >
                        </v-btn>
                    </template>
                    <span>Ver Presupuesto</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            fab
                            :to="{
                                path: `/registrr-facturas?cliente=${proyecto.usuario_id}&id_proyecto=${proyecto.id}`,
                            }"
                            :loading="isloading"
                            :disabled="isloading"
                            class="mx-2"
                            v-bind="attrs"
                            v-on="on"
                            readonly
                            style="background-color: #1d2735 !important"
                        >
                            <v-icon class="white--text">mdi-calculator</v-icon>
                        </v-btn>
                    </template>
                    <span>Crear Factura</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            fab
                            :to="{
                                path: `/registrr-facturas-pro?cliente=${proyecto.usuario_id}`,
                            }"
                            :loading="isloading"
                            :disabled="isloading"
                            class="mx-2"
                            v-bind="attrs"
                            v-on="on"
                            readonly
                            style="background-color: #1d2735 !important"
                        >
                            <v-icon class="white--text"
                                >mdi-calculator-variant</v-icon
                            >
                        </v-btn>
                    </template>
                    <span>Crear Factura Proforma</span>
                </v-tooltip>
                <router-link
                    v-if="proyecto.id != null"
                    :to="`lista-facturas?id_cliente=${proyecto.usuario_id}`"
                >
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                fab
                                :loading="isloading"
                                :disabled="isloading"
                                class="mx-2"
                                v-bind="attrs"
                                v-on="on"
                                readonly
                                style="background-color: #1d2735 !important"
                            >
                                <v-icon class="white--text"
                                    >mdi-file-pdf</v-icon
                                >
                            </v-btn>
                        </template>

                        <span>Ver Facturas Enviadas</span>
                    </v-tooltip>
                </router-link>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            fab
                            :loading="isloading"
                            :disabled="isloading"
                            color="amber accent-3"
                            class="mx-2"
                            v-bind="attrs"
                            v-on="on"
                            readonly
                        >
                            <v-icon class="white--text">mdi-at</v-icon>
                        </v-btn>
                    </template>
                    <span>Enviar Mail</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <!-- Botones Proyecto 1a linea -->

        <!-- Botones Proyecto 2a linea -->
        <v-row class="mt-3">
            <v-col cols="12">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            fab
                            @click="volver"
                            :loading="isloading"
                            :disabled="isloading"
                            color="blue"
                            class="mx-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon class="white--text"
                                >mdi-arrow-left-bold-outline</v-icon
                            >
                        </v-btn>
                    </template>
                    <span>Volver</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            fab
                            @click="saveProyecto"
                            :loading="isloading"
                            :disabled="isloading"
                            color="success"
                            class="mx-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon class="white--text"
                                >mdi-content-save-all</v-icon
                            >
                        </v-btn>
                    </template>
                    <span>Guardar Proyecto</span>
                </v-tooltip>
            </v-col>
        </v-row>

        <!-- Botones Proyecto 1a linea -->
        <WhatsAppDialog
            :name="proyecto.usuario.nombre"
            v-model="dialog_whatsapp"
            :to="proyecto.usuario.telefono"
        >
        </WhatsAppDialog>

        <SemaforoEstadoFacturacion
            @change="setEstadoFacturacion"
            v-model="dialog_semaforo"
            :proyecto="proyecto"
        ></SemaforoEstadoFacturacion>
    </v-container>
</template>
<script>
import GastoExterno from "./GastoExterno.vue";
import WhatsAppDialog from "../../potenciales/componentes/WhatsAppDialog.vue";
import { provincias_mixin } from "../../../global_mixins/provincias_mixin";
import { servicios_mixin } from "../../../global_mixins/servicios_mixin";
import { estados_mixin } from "../../../global_mixins/estados_mixin";
import FileInput from "../../../global_components/FileInput.vue";
import VFileComponent from "../../../global_components/VFileComponent.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import formUsuario from "./formUsuario.vue";
import tabArchivo from "../../../global_components/tabArchivo.vue";
import SemaforoEstadoFacturacion from "../../facturas/componentes/SemaforoEstadoFacturacion.vue";
import TicketDataTableVue from "../../../components/ticket/TicketDataTable.vue";
import FileManager from "../../../global_components/FileManager.vue";
import { rules } from "../../../utils/rules";
export default {
    components: {
        "file-input": FileInput,
        VFileComponent,
        formUsuario,
        tabArchivo,
        WhatsAppDialog,
        SemaforoEstadoFacturacion,
        TicketDataTableVue,
        GastoExterno,
        FileManager,
    },
    mixins: [provincias_mixin, servicios_mixin, estados_mixin],
    data() {
        return {
            rules: rules,
            validFormSeguimiento: true,
            dialog_semaforo: false,
            estado_edit: {},
            save_estado: {},

            estado: {
                id_estado: null,
            },
            dialog_whatsapp: false,
            proyecto_estados: [],
            seguimiento: {
                id: null,
                fecha: null,
                activo: false,
                alarma: false,
                hora: null,
                comentario: null,
            },
            Seguimientos: [],
            seguimientos_headers: [
                {
                    text: "Nombre",
                    value: "proyecto.nombre",
                    sortable: false,
                },
                {
                    text: "comentario",
                    value: "comentario",
                    sortable: false,
                },
                {
                    text: "Fecha",
                    value: "fecha",
                    sortable: false,
                },
                {
                    text: "Acciones",
                    value: "action",
                    sortable: false,
                },
            ],
            user_to_pick: null,
            editor: ClassicEditor,
            editorData: "<p>Escriba Aqui Observaciones o contenido.</p>",
            editorConfig: {
                toolbar: {
                    items: [
                        "heading",
                        "bold",
                        "italic",
                        "bulletedList",
                        "numberedList",
                        "link",
                        "inserttable",
                    ],
                },
            },
            menu: false,
            menu: "",
            menu2: false,
            menu2: "",
            menu3: false,
            menu3: "",
            menu4: false,
            menu4: "",
            proyecto: {
                id: null,
                usuario_id: null,
                pvp: 0,
                semanal: 0, // 0 total, 1 semanal, 2 mensual
                pvp_gasto: 0,
                lead_form: {
                    name: "",
                },
                gastos_externos: [],
                usuario: {
                    id: "",
                    user_id: localStorage.getItem("user_id"),
                    nombre: "",
                    nombre_fiscal: "",
                    cif: "",
                    telefono: "",
                    email: "",
                    role: 2,
                    direccion: "",
                    codigo_postal: "",
                    localidad: "",
                    provincia_id: 35,
                    cuenta: "00000000000000000000",
                    fecha_alta: new Date().toISOString().substr(0, 10),
                    observaciones: "",
                    avatar: null,
                    id_referidos: null,
                },
                estado_id: 2,
                servicio: {},
                servicio_id: null,
                fecha_alta: new Date().toISOString().substr(0, 10),
                detalle_servicio: null,
                detalles_gasto: null,
                nombre: "",
                archivos: [],
                porc_realizado: 0,
                activo: false,
                observaciones: "",
                estado_facturacion: {
                    id: null,
                    id_proyecto: null,
                    parcial: 0,
                },
            },
            clienteId: null,
            servicio: {
                id: null,
                nombre: "",
            },
            estado: {
                id: 1,
            },
            descripcion: "",
            fecha: new Date().toISOString().substr(0, 10),
            estado_proyecto_id: "",
            id_items_proyecto: [],
            finalizado: false,
            total_pagos_ingresos: 0, //
            total_pendiente: 0, //
            headers: [
                {
                    text: "Descripcion",
                    value: "descripcion",
                    sortable: false,
                },
                {
                    text: "Fecha",
                    value: "fecha",
                    sortable: false,
                },
                {
                    text: "Estado",
                    value: "finalizado",
                    sortable: false,
                },
                {
                    text: "Acciones",
                    value: "action",
                    sortable: false,
                },
            ],
            headers_usuarios: [
                {
                    text: "id",
                    value: "id_usuario",
                    sortable: false,
                },
                {
                    text: "Nombre",
                    value: "usuario.nombre",
                    sortable: false,
                },
                {
                    text: "Nombre Fiscal",
                    value: "usuario.nombre_fiscal",
                    sortable: false,
                },
                {
                    text: "Rol",
                    value: "role",
                    sortable: false,
                },
                {
                    text: "Acciones",
                    value: "action",
                    sortable: false,
                },
            ],
            headers_proyectos: [
                {
                    text: "Num. Factura",
                    value: "nro_anio_factura",
                    align: "center",
                    sortable: false,
                },
                {
                    text: "Fecha Factura",
                    value: "fecha",
                    sortable: false,
                },
                {
                    text: "num. Presupuesto",
                    value: "nro_presupuesto",
                    align: "center",
                    sortable: false,
                },
                {
                    text: "Importe Factura",
                    value: "total",
                    align: "center",
                    sortable: false,
                },
                {
                    text: "Ingresos Totales Factura",
                    value: "total_pagos_ingresos",
                    align: "center",
                    sortable: false,
                },
            ],
            roles: [
                {
                    id: 1,
                    role: "Administrador",
                },
                {
                    id: 2,
                    role: "Cliente",
                },
            ],
            usuarios: [],
            files: [],
            imagePreview: [],
            csrf: document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute("content"),
            user_id: localStorage.getItem("user_id"),
            base_64_image: null,
            items: [],
            itemsEstado: [],
            itemsUsuarios: [],
            empleados: [],
            ingresosProyecto: [],
            facturasProyectos: [],
            // estados: [],
        };
    },
    created() {
        if (this.$route.query.id) {
            this.getProyectoById(this.$route.query.id);
        }

        this.clienteid = this.$route.query.clienteid;

        this.getUsuarios();
        this.getEmpleados();
        this.generateHoras();
        this.fillClienteId();
        this.getProyectoEstado();
        // this.getEstados();
    },

    methods: {
        // Acciones de tab "Proyecto"
        //adaptamos el getProyectoById para traernos los items de estados en la edición
        async getProyectoById(proyecto_id) {
            try {
                const response = await axios.get(
                    `api/get-proyecto-by-id/${proyecto_id}`
                );
                this.proyecto = response.data;
                this.itemsEstado = response.data.estados_proyecto;
                this.itemsUsuarios = response.data.usuarios ?? [];
                this.pushItemsEstadoId(this.itemsEstado);
                this.getingresosByProyecto(proyecto_id);
                this.getSeguimientos();
            } catch (error) {
                console.log(error);
            }
        },
        async saveProyecto() {
            try {
                if (this.proyecto.activo) {
                    this.proyecto.activo = true;
                } else {
                    this.proyecto.activo = false;
                }

                var formData = new FormData();
                formData.append("proyecto", JSON.stringify(this.proyecto));
                formData.append(
                    "itemsEstado",
                    JSON.stringify(this.itemsEstado)
                );
                formData.append(
                    "idItemsEstado",
                    JSON.stringify(this.id_items_proyecto)
                );
                formData.append("usuarios", JSON.stringify(this.itemsUsuarios));

                // let archivos = this.proyecto.archivos.filter(
                //     (archivo) => !archivo.id
                // );

                // Archivos y carpetas
                let archivos = this.proyecto.archivos_organizados.filter(
                    (item) =>
                        (item.type === "file" || item.type == null) && !item.id
                );
                let carpetas = this.proyecto.archivos_organizados.filter(
                    (item) => item.type === "folder"
                );

                // Agregar JSON de carpetas al FormData
                // formData.append("carpetas", JSON.stringify(carpetas));

                // Agregar archivos sueltos al FormData
                archivos.forEach((item, i) =>
                    formData.append("itemsFiles[" + i + "]", item.file)
                );

                // Agregar carpetas y sus archivos
                carpetas.forEach((carpeta, carpetaIndex) => {
                    carpeta.files.forEach((archivo, archivoIndex) => {
                        console.log("archivo", archivo);

                        formData.append(
                            `carpetas[${carpetaIndex}][files][${archivoIndex}]`,
                            archivo.file
                        );
                    });
                    formData.append(
                        `carpetas[${carpetaIndex}][name]`,
                        carpeta.name
                    );
                });

                let res = await axios.post("api/save-proyecto", formData);
                this.proyecto = res.data.proyecto;
                this.$toast.sucs("Proyecto guardado con exito");
            } catch (error) {
                console.log(error);

                this.$toast.error(
                    "Error guardando Proyecto, Compruebe todos los campos requeridos"
                );
            }
        },
        pushItemsEstadoId(items) {
            const itemsPreview = items;
            Object.keys(itemsPreview).forEach((i) => {
                this.id_items_proyecto.push(itemsPreview[i].id);
            });
        },
        getingresosByProyecto(proyecto_id) {
            this.total_pagos_ingresos = 0;
            this.total_pendiente = 0;
            axios.get(`api/get-ingreso-by-pro_id/${proyecto_id}`).then(
                (res) => {
                    this.ingresosProyectos = res.data;
                    this.total_pagos_ingresos = parseFloat(
                        this.ingresosProyectos.total_pagos_ingresos
                    ).toFixed(2);
                    this.total_pendiente = parseFloat(
                        this.proyecto.pvp -
                            this.ingresosProyectos.total_pagos_ingresos
                    ).toFixed(2);
                    this.getFacturasByProyecto(proyecto_id);
                },
                (err) => {
                    this.$toast.error(
                        "Error consultando Ingresos de proyectos"
                    );
                }
            );
        },
        getFacturasByProyecto(proyecto_id) {
            axios.get(`api/get-facturas-by-proyecto/${proyecto_id}`).then(
                (res) => {
                    this.facturasProyectos = res.data;
                    /*if (this.facturasProyectos.length > 0) {
                        this.facturasProyectos[0].total_pagos_ingresos =
                            this.total_pagos_ingresos;
                    }*/
                },
                (err) => {
                    this.$toast.error("Error consultando Facturas");
                }
            );
        },
        setEstadoFacturacion(item) {
            this.proyecto.estado_facturacion = item;
        },

        // Acciones del crud de dynamic select de servicios
        createServicio() {
            axios.post("api/servicio", this.servicio).then(
                (res) => {
                    this.getServicios();
                },
                (res) => {}
            );
        },
        updateServicio() {
            axios.put(`api/servicio/${this.servicio.id}`, this.servicio).then(
                (res) => {
                    this.getServicios();
                    this.potencial.servicio_id = null;
                    this.$nextTick(() => {
                        this.potencial.servicio_id = this.servicio.id || null; // Selecciona el primer elemento actualizado
                    });
                },
                (res) => {}
            );
        },
        updateServicioS(index) {
            this.servicio = this.servicios[index];
        },
        deleteServicio(id) {
            axios.delete(`api/servicio/${id}`).then(
                (res) => {
                    this.getServicios();
                    this.delete_dialog = false;
                    this.potencial.servicio_id = null;
                },
                (res) => {}
            );
        },

        // Acciones de tab "Cliente"
        fillClienteId() {
            if (this.$route.query.clienteid) {
                axios
                    .get(`api/get-usuario-by-id/${this.clienteid}`)
                    .then((res) => {
                        this.proyecto.usuario = res.data.user;
                    });
            }
        },

        // Acciones del tab "estado del proyecto"
        saveproyectoEstado() {
            axios
                .post("api/save-proyecto-estados", this.save_estado)
                .then((res) => {
                    this.getProyectoEstado();
                    this.save_estado = {};
                });
        },
        deleteProyectoEstado(id) {
            axios
                .post("api/delete-proyecto-estados", {
                    id: id,
                })
                .then((res) => {
                    this.getProyectoEstado();
                });
        },
        addStatus() {
            if (this.estado.id_estado != "") {
                let str = this.proyecto_estados.find(
                    (element) => element.id == this.estado.id_estado
                );
                const estado = {
                    estado: str,
                    id_estado: this.estado.id_estado,
                    fecha: this.fecha,
                    finalizado: this.finalizado,
                };
                this.itemsEstado.push(estado);
            }
            this.estado_proyecto_id = "";
            this.descripcion = "";
            this.finalizado = false;
            this.fecha = new Date().toISOString().substr(0, 10);
        },
        deleteItem(item) {
            let idstring = item.id.toString();
            if (idstring.includes("a")) {
                const search = this.itemsEstado.findIndex(
                    (val) => val.id === idstring
                );
                if (search > -1) {
                    this.itemsEstado.splice(search, 1);
                    this.id_items_proyecto.splice(search, 1);
                }
            } else {
                const search = this.itemsEstado.findIndex(
                    (val) => val.id == item.id
                );
                if (search > -1) {
                    this.itemsEstado.splice(search, 1);
                    this.id_items_proyecto.splice(search, 1);
                }
                axios.get(`api/delete-proyecto-estado/${item.id}`).then(
                    (res) => {
                        this.$toast.sucs(
                            "Estado existente borrado exitosamente"
                        );
                    },
                    (err) => {
                        this.$toast.error("Error al borrar estado");
                    }
                );
            }
        },
        editItem(item) {
            this.estado_edit.index = this.itemsEstado.indexOf(item);
            this.estado_edit.id = item.id;
            this.estado_edit.descripcion = item.descripcion;
            this.estado_edit.fecha = item.fecha.substr(0, 10);
            this.estado_edit.finalizado = item.finalizado;
            this.dialog_estado = 1;
        },
        changeFinalizado(itemE) {
            if (itemE.id == null) {
                itemE.finalizado = !itemE.finalizado;
                this.$toast.warn(
                    "Estado actualizado, los cambios se haran efectivos al presionar el boton guardar"
                );
            } else {
                axios.get(`api/update-project-status/${itemE.id}`).then(
                    (res) => {
                        itemE.finalizado = !itemE.finalizado;
                        this.$toast.sucs("Estado actualizado");
                    },
                    (err) => {
                        this.$toast.error(
                            "Debe guardar/actualizar antes de poder cambiar el estado"
                        );
                    }
                );
            }
        },

        // Acciones del tab "usuarios asignados"
        addUserList() {
            // this.getEmpleados();
            // this.user_to_pick = this.empleados;
            if (this.user_to_pick != null) {
                // console.log(this.user_to_pick);
                let finusuraio = this.itemsUsuarios.find(
                    (element) => element.id_usuario == this.user_to_pick.id
                );
                if (finusuraio == null) {
                    let elemento = {
                        id_usuario: this.user_to_pick.id,
                        usuario: this.user_to_pick,
                    };

                    this.itemsUsuarios.push(elemento);
                }
            }
            this.user_to_pick = null;
        },
        deleteItemUsers(item) {
            // console.log(item);
            const search = this.itemsUsuarios.findIndex(
                (val) => val.id_usuario === item.id_usuario
            );
            if (search > -1) {
                this.itemsUsuarios.splice(search, 1);
            }
        },

        // Acciones de tab "Seguimiento"
        getSeguimientos() {
            const self = this;
            axios
                .get(`api/get-tareas-proyecto?cliente=${this.proyecto.id}`)
                .then(
                    (res) => {
                        self.Seguimientos = res.data;
                    },
                    (err) => {
                        this.$toast.error("Error consultando Seguimientos");
                    }
                );
        },
        saveSeguimiento() {
            const self = this;
            self.$refs.formSeguimiento.validate();

            if (self.validFormSeguimiento) {
                // console.log(this.seguimiento);
                this.seguimiento.id_proyecto = this.proyecto.id;
                axios
                    .post("api/save-tareas-proyecto", this.seguimiento)
                    .then(function (response) {
                        // self.seguimiento = {};
                        self.clearSeguimiento();
                        self.$toast.sucs("Agregado seguimiento con exito");
                        self.getSeguimientos();
                    });
            }
        },
        deleteSeguimiento(item) {
            axios
                .post("api/cancel-tareas-proyecto", {
                    id: item.id,
                })
                .then(
                    (res) => {
                        this.$toast.sucs("Seguimiento eliminado");
                        this.dialog = false;
                        this.getSeguimientos();
                    },
                    (err) => {
                        this.$toast.error("Error eliminando Seguimiento");
                    }
                );
        },
        clearSeguimiento() {
            this.seguimiento = {
                id: null,
                fecha: null,
                activo: false,
                alarma: false,
                hora: null,
                comentario: null,
            };
        },

        // Metodos generales
        getUsuarios() {
            axios.get(`api/get-usuarios`).then(
                (res) => {
                    this.usuarios = res.data.users;
                },
                (err) => {
                    this.$toast.error("Error consultando clientes");
                }
            );
        },
        getEmpleados() {
            axios.post(`api/get-usuarios-empleados`, this.filtros).then(
                (res) => {
                    this.empleados = res.data.users.data;
                    this.empleados.unshift("");
                },
                (err) => {
                    this.$toast.error("Error consultando empleados");
                }
            );
        },
        generateHoras() {
            this.horas = [];
            for (let val = 9; val <= 19; val += 0.5) {
                let str = val.toString().split(".");
                let txt = "";
                if (str.length > 1) {
                    txt = str[0].toString().padStart(2, "0") + ":30";
                } else {
                    txt = val.toString().padStart(2, "0") + ":00";
                }
                this.horas.push({
                    text: txt,
                    value: val,
                });
            }
        },
        getProyectoEstado() {
            axios.get("api/get-proyecto-estados").then((res) => {
                this.proyecto_estados = res.data;
            });
        },

        volver() {
            // volver al crear
            let tipo = "";
            if (
                this.$route.query.tipo != null &&
                this.$route.query.tipo != "null"
            ) {
                tipo = "&tipo=" + this.$route.query.tipo;
            }
            if (this.$route.query.seguimiento_cliente == "1") {
                let fecha = "";
                if (this.$route.query.fecha != null) {
                    fecha = `?fecha=${this.$route.query.fecha}`;
                }
                this.$router.push(`/calendario-seguimiento-cliente${fecha}`);
            } else if (this.clienteid) {
                this.$router.push(
                    `/${this.$route.meta.to}?id=${this.clienteid}${tipo}`
                );
            }
            // volver al editar
            else {
                this.$router.push(
                    `/${this.$route.meta.to}?id=${this.proyecto.usuario.id}${tipo}`
                );
            }
        },
        setFiles(files) {
            const filesPreview = files;
            Object.keys(filesPreview).forEach((i) => {
                const file = filesPreview[i];
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.imagePreview.push(reader.result);
                };
                this.imagePreview = [];
                reader.readAsDataURL(file);
            });
            if (files !== undefined) {
                this.files = files;
                this.disableUploadButtonImage = false;
            }
        },
        deleteFile(item) {
            const index = this.items.findIndex((val) => val.name == item.name);
            if (index > -1) {
                this.items.splice(index, 1);
            }
        },

        goingTo() {
            let params = {
                from: "proyecto",
            };
            if (this.$route.query.id != null) {
                params.id_proyecto = this.$route.query.id;
            }
            this.$router.push({
                path: `/registrar-presupuesto`,
                query: params,
            });
        },
        // Metodos en desuso
        /*getMethodsForm() {
                axios.get(`api/get-methods-form`).then(
                    (res) => {
                        this.provincias = res.data.provincias;
                    },
                    (res) => {
                        this.$toast.error("Error consultando Usuario");
                    }
                );
            },
            getUsuarioById(usuario_id) {
                axios.get(`api/get-usuario-by-id/${usuario_id}`).then(
                    (res) => {
                        this.usuario = res.data.user;
                    },
                    (res) => {
                        this.$toast.error("Error consultando Usuario");
                    }
                );
            },
            editElement() {
                let elemento = this.itemsEstado[this.estado_edit.index];
                elemento.descripcion = this.estado_edit.descripcion;
                elemento.fecha = this.estado_edit.fecha;
                elemento.finalizado = this.estado_edit.finalizado;
                this.closeDialog();
            },
            closeDialog() {
                this.dialog_estado = 0;
            },
            getEstados() {
                axios.get("api/get-estados").then((res) => {
                    this.estados = res.data;
                });
            },*/
    },
    watch: {
        //
    },
    computed: {
        getColorFacturacion() {
            const item = this.proyecto;
            if (item.estado_facturacion == null) {
                return {
                    color: "red",
                    text: "Pendiente Facturar",
                };
            }
            if (item.estado_facturacion.parcial == 1) {
                return {
                    color: "yellow",
                    text: "Facturado parcial",
                };
            }

            return {
                color: "green",
                text: "Facturado",
            };
        },
        admin() {
            const role = this.role;
            return role == 1 || role == 5 || role == 7;
        },
        marketing() {
            const role = this.role;

            return role == 6;
        },
        vendedor() {
            const role = this.role;
            return role == 1 || role == 5 || role == 7 || role == 9;
        },
        role() {
            let role = localStorage.getItem("role");
            // console.log(role);
            return role;
        },
        isloading() {
            return this.$store.getters.getloading;
        },
        errors() {
            return this.$store.getters.geterrors;
        },
        total_gastos() {
            const total = this.proyecto.gastos_externos.reduce(
                (accumulator, currentValue) => {
                    return accumulator + parseFloat(currentValue.importe);
                },
                0
            );
            return total;
        },
    },
};
</script>
<style>
div.v-messages.theme--light {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
    padding-top: -1px !important;
    padding-bottom: -1px !important;
}

div.v-text-field__details {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
    padding-top: -1px !important;
    padding-bottom: -1px !important;
}
</style>
