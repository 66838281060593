const modulo_roles = {
	strict: false,

  state: {
    roles: []
  },	

	getters: {
		get_roles: state => state.roles,
	},

	mutations: {
		set_roles: (state, lista_roles) => state.roles = lista_roles,
	},

	actions: {
		setRoles: (context, lista_roles) => context.commit('set_roles', lista_roles),
	}
}

export default modulo_roles;
