export const roles_mixin = {
  created(){
    if(this.roles.length == 0){
      this.getRoles()
    }
  },

  methods:{
    getRoles(){
      axios.get(`api/rol`).then(res => {
          this.$store.dispatch('setRoles', res.data.success)
      }, res => {
         this.$toast.error('Error cargando roles')
      })
    }
  },

  computed: {
    roles() {
      return this.$store.getters.get_roles
    }
  }
}
