// import FormPresupuesto from "../componentes/FormPresupuesto.vue";
import ListaPresupuestos from "../componentes/ListaPresupuestos.vue";
const routes = [
    ...route(`/lista-presupuestos`, ListaPresupuestos, {
        Auth: true,
    }),

    // ...route(`/registrar-presupuesto`, RegistrarPresupuesto, {
    //     Auth: true,
    // }),
];

function route(path, component = Default, meta = {}) {
    return [
        {
            path,
            component,
            meta,
        },
    ];
}

export default routes;
