<template>
    <v-container>
        <v-card shaped class="mx-4 my-4 pa-1">
            <v-card-title>
                <v-toolbar flat color="#1d2735" dark style="border-radius: 5px">
                    <v-icon class="white--text" style="font-size: 45px"
                        >mdi-account-supervisor-circle</v-icon
                    >
                    <pre><v-toolbar-title><h2>{{ $route.query.id ? 'Editar reunióm' : 'Crear reunión'}}</h2></v-toolbar-title></pre>
                </v-toolbar>
            </v-card-title>

            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-form ref="form" v-model="validForm" class="mt-3">
                            <!-- datos generales de la reunion -->
                            <v-row>
                                <v-col cols="12" md="3">
                                    <date-select
                                        v-model="reunion.fecha"
                                        label="Fecha"
                                        :rules="[rules.required]"
                                    >
                                    </date-select>
                                </v-col>
                                <v-col col="12" md="3">
                                    <v-autocomplete
                                        outlined
                                        label="Hora Desde"
                                        v-model="reunion.hora_desde"
                                        :items="horas"
                                        item-value="value"
                                        item-text="text"
                                        :rules="[rules.required]"
                                    >
                                    </v-autocomplete
                                ></v-col>

                                <v-col col="12" md="3">
                                    <v-autocomplete
                                        outlined
                                        label="Hora Hasta"
                                        v-model="reunion.hora_hasta"
                                        :items="horas"
                                        item-value="value"
                                        item-text="text"
                                        :rules="[rules.required]"
                                    >
                                    </v-autocomplete
                                ></v-col>

                                <v-col col="12" md="3">
                                    <v-autocomplete
                                        v-model="reunion.id_proyecto"
                                        label="Proyecto"
                                        :items="proyectos"
                                        item-text="nombre"
                                        item-value="id"
                                        outlined
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>

                            <v-row>
                                <!-- Agregar asistentes -->
                                <v-col cols="6">
                                    <v-autocomplete
                                        label="Asistente"
                                        v-model="id_asistente"
                                        :items="empleados"
                                        item-text="nombre"
                                        item-value="id"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="6">
                                    <v-btn
                                        label="asistente"
                                        @click="AddAsistente"
                                        color="success"
                                        >Agregar Asistente</v-btn
                                    >
                                </v-col>
                                <v-col cols="12">
                                    <v-data-table
                                        :headers="headers"
                                        :items="reunion.asistentes"
                                        item-key="id"
                                        class="elevation-1"
                                    >
                                        <template v-slot:item.action="{ item }">
                                            <v-icon
                                                @click="
                                                    deleteAsistente(
                                                        item.id_asistente
                                                    )
                                                "
                                                small
                                                class="mr-2"
                                                color="red"
                                            >
                                                mdi-trash-can
                                            </v-icon>
                                        </template>
                                    </v-data-table>
                                </v-col>

                                <!-- Comentarios y acta -->
                                <v-col cols="12">
                                    <v-textarea
                                        label="Comentario"
                                        v-model="reunion.comentario"
                                        outlined
                                        :rules="[rules.required]"
                                    >
                                    </v-textarea>
                                </v-col>
                            </v-row>

                            <!-- <v-row>
                                <v-col cols="12">
                                    <v-btn
                                        v-if="!reunion.id"
                                        :disabled="isloading"
                                        color="success"
                                        class="white--text"
                                        @click="savereunion"
                                        >Guardar</v-btn
                                    >
                                    <v-btn
                                        @click="savereunion"
                                        v-if="reunion.id"
                                        :disabled="isloading"
                                        color="success"
                                        class="white--text"
                                        >Actualizar</v-btn
                                    >
                                    <v-btn
                                        @click="openDeleteDialog"
                                        v-if="reunion.id"
                                        :disabled="isloading"
                                        color="red"
                                        class="white--text"
                                        >Eliminar</v-btn
                                    >
                                </v-col>
                            </v-row> -->
                        </v-form>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions class="mx-2">
                <v-btn
                    v-if="!reunion.id"
                    :disabled="isloading"
                    color="success"
                    class="white--text"
                    @click="savereunion"
                    >Guardar</v-btn
                >
                <v-btn
                    @click="savereunion"
                    v-if="reunion.id"
                    :disabled="isloading"
                    color="success"
                    class="white--text"
                    >Actualizar</v-btn
                >
                <v-btn
                    @click="openDeleteDialog"
                    v-if="reunion.id"
                    :disabled="isloading"
                    color="red"
                    class="white--text"
                    >Eliminar</v-btn
                >
                <v-btn
                    @click="seguimiento_dialog = true"
                    v-if="reunion.id && reunion.id_proyecto"
                    :disabled="isloading"
                    color="light-blue"
                    class="white--text"
                    >Crear seguimiento</v-btn
                >

                <!-- si proyecto.estado_id es 3 es un potencial y no permite crear ticket -->
                <div class="mx-2">
                    <TicketButtonVue
                        v-if="
                            reunion.id &&
                            reunion.id_proyecto &&
                            proyecto.estado_id != 3
                        "
                        :icon="false"
                        :color="'blue'"
                        :classes="'white--text'"
                        @confirm="crearTicket"
                    />
                </div>

                <!-- <v-btn
                    @click="openDeleteDialog"
                    v-if="reunion.id && estado_proyecto != 3"
                    :disabled="isloading"
                    color="blue"
                    class="white--text"
                    >Crear ticket</v-btn
                > -->
            </v-card-actions>
        </v-card>

        <!-- delete dialog -->
        <v-dialog v-model="dialog" max-width="500px">
            <v-card>
                <v-card-title
                    class="text-h5 aviso"
                    style="
                        justify-content: center;
                        background: #1d2735;
                        color: white;
                    "
                >
                    Aviso
                </v-card-title>
                <v-card-text style="text-align: center">
                    <h2>¿Estás seguro que deseas eliminar?</h2>
                </v-card-text>
                <v-card-actions class="pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        large
                        @click="
                            dialog = false;
                            selectedItem = {};
                        "
                        >Cancelar</v-btn
                    >
                    <v-btn color="success" large @click="deleteReunion()"
                        >Confirmar</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- seguimiento dialog -->
        <SeguimientoDialogVue
            v-model="seguimiento_dialog"
            @confirm="crearSeguimineto"
        />
    </v-container>
</template>

<script>
import TicketButtonVue from "../../../base_components/inicio/ComponentsTfg/TicketButton.vue";
import SeguimientoDialogVue from "../../../components/general/SeguimientoDialog.vue";
import RichTextComponentVue from "../../tickets/componentes/RichTextComponent.vue";

export default {
    components: { RichTextComponentVue, SeguimientoDialogVue, TicketButtonVue },
    data() {
        return {
            rules: {
                required: (value) => !!value || "Requerido.",
            },
            headers: [
                {
                    text: "Nombre",
                    value: "asistente.nombre",
                },
                {
                    text: "Acciones",
                    value: "action",
                    sortable: false,
                },
            ],
            dialog: false,
            id_asistente: null,
            horas: [],
            proyectos: [],
            empleados: [],
            reunion: {
                id: null,
                id_proyecto: null,
                fecha: "",
                fecha_entrega: "",
                hora_desde: "",
                hora_hasta: "",
                comentario: "",
                acta: "",
                asistentes: [],
            },
            validForm: true,
            seguimiento_dialog: false,
        };
    },
    filters: {
        //
    },
    watch: {
        //
    },
    created() {
        this.generateHoras();
        this.getUsuarios();
        this.initializeData();
    },
    methods: {
        async initializeData() {
            try {
                await this.getProyectos(); // Asegúrate de que los proyectos estén cargados

                if (this.$route.query.id) {
                    await this.getreunion(this.$route.query.id); // Obtén los datos de la reunión después de que los proyectos estén cargados
                }
            } catch (error) {
                console.error("Error inicializando data:", error);
            }
        },

        // Acciones de reunion
        async getreunion(id) {
            const response = await axios.get(`api/get-reunion?id=${id}`);
            this.reunion = response.data;
        },
        savereunion() {
            const self = this;
            self.$refs.form.validate();

            if (self.validForm) {
                axios
                    .post("api/save-reunion", this.reunion)
                    .then(function (response) {
                        self.$router.push("calendario-reuniones");
                    });
            }
        },
        openDeleteDialog() {
            this.dialog = true;
        },
        deleteReunion() {
            axios.post(`api/delete-reunion`, { id: this.reunion.id }).then(
                (res) => {
                    this.$router.push("calendario-reuniones");
                },
                (err) => {
                    this.$toast.error("Error consultando empleados");
                }
            );
        },

        // metodos generales
        generateHoras() {
            this.horas = [];
            for (let val = 9; val <= 19; val += 0.5) {
                let str = val.toString().split(".");
                let txt = "";
                if (str.length > 1) {
                    txt = str[0].toString().padStart(2, "0") + ":30";
                } else {
                    txt = val.toString().padStart(2, "0") + ":00";
                }
                this.horas.push({ text: txt, value: val });
            }
        },
        getUsuarios() {
            axios.post(`api/get-usuarios-empleados-all`).then(
                (res) => {
                    this.empleados = res.data.users;
                },
                (err) => {
                    this.$toast.error("Error consultando empleados");
                }
            );
        },
        async getProyectos() {
            const response = await axios.get("api/proyectos/activos");
            this.proyectos = response.data.success;
        },

        // Acciones de asistentes
        AddAsistente() {
            if (this.id_asistente) {
                this.reunion.asistentes.push({
                    id_asistente: this.id_asistente,
                    asistente: this.empleados.find(
                        (element) => element.id == this.id_asistente
                    ),
                });
            }
            this.id_asistente = null;
        },
        deleteAsistente(id) {
            if (id) {
                const asis = this.reunion.asistentes.find(
                    (element) => element.id_asistente == id
                );
                const index = this.reunion.asistentes.indexOf(asis);
                if (index > -1) {
                    // only splice array when item is found

                    this.reunion.asistentes.splice(index, 1); // 2nd parameter means remove one item only
                }
            }
            this.id_asistente = null;
        },

        // Crear seguimiento
        async crearSeguimineto(seguimiento) {
            seguimiento.id_proyecto = this.reunion.id_proyecto;

            await axios.post("api/save-tareas-proyecto", seguimiento);

            this.seguimiento_dialog = false;
            this.$toast.success(
                `Agregado seguimineto para el proyecto ${this.proyecto.nombre}`
            );
        },

        // Crear ticket de soporte
        crearTicket(ticket) {
            ticket.id_proyecto = this.reunion.id_proyecto;
            ticket.id_usuario = this.proyecto.usuario_id;
            console.log("ticket", ticket);
            axios.post("api/create-ticket", ticket).then((res) => {
                this.$toast.sucs(
                    `Creado ticket de soporte para el proyecto ${this.proyecto.nombre}`
                );
            });
        },
    },
    computed: {
        isloading: function () {
            return this.$store.getters.getloading;
        },

        // proyecto es utilizado para almacenar la data del proyecto seleccionado de la reunion
        proyecto: function () {
            // Establece el object por defecto
            let proyecto = {
                id: null,
                nombre: "",
                fecha_alta: "",
                activo: 1,
                usuario_id: null,
                estado_id: 2,
            };

            // Si reunion tiene un proyecto asociado entonces devuelve el proyecto, sino el por defecto
            if (this.reunion.id_proyecto) {
                proyecto = this.proyectos.find(
                    (element) => element.id == this.reunion.id_proyecto
                );
            }

            return proyecto;
        },
    },
};
</script>
