import modulo_estado from "./modulos/modulo_estado";
import modulo_provincias from "./modulos/modulo_provincias";
import modulo_estados from "./modulos/modulo_estados";
import modulo_servicios from "./modulos/modulo_servicios";
import modulo_filtros from "./modulos/modulo_filtros";
import modulo_clientes from "./modulos/modulo_clientes";
import modulo_chat from "./modulos/modulo_chat";
import modulo_mensajes_pendientes from "./modulos/modulo_mensajes_pendientes";
import Vue from "vue";
import Vuex from "vuex";
import modulo_proyectos from "./modulos/modulo_proyectos";
import modulo_roles from "./modulos/modulo_roles";

Vue.use(Vuex);

const store = new Vuex.Store({
    strict: false,

    modules: {
        estado: modulo_estado,
        provincias: modulo_provincias,
        estados: modulo_estados,
        servicios: modulo_servicios,
        filtros: modulo_filtros,
        clientes: modulo_clientes,
        chats: modulo_chat,
        proyectos: modulo_proyectos,
        por_aprobar:modulo_mensajes_pendientes,
        modulo_roles: modulo_roles,
    },
});

export default store;
